import { registerApplication, start } from 'single-spa';
import { MICROFRONTS } from './constants/imports';
import i18n from './i18n';

//components stencil
import { defineCustomElements } from 'components-stencil/loader';
defineCustomElements(window);

import './styles/main.scss';

MICROFRONTS.forEach(mf => {
  registerApplication(mf.name, mf.module, mf.route, { i18n });
});

start({
  urlRerouteOnly: true,
});
