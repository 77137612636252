// MFE modules
const MICROFRONTS = [
  {
    name: 'navigation',
    alias: 'bcc-nav',
    path: 'https://modules.bcc.robinfood.com/navigation/remoteEntry.js',
    module: () => import('bcc-nav/Navigation'),
    route: () => location.pathname.startsWith('/'),
  },
  {
    name: 'login',
    alias: 'bcc-login',
    path: 'https://modules.bcc.robinfood.com/login/remoteEntry.js',
    module: () => import('bcc-login/Login'),
    route: () => location.pathname.startsWith('/login'),
  },
  {
    name: 'coupons',
    alias: 'bcc-coupons',
    path: 'https://modules.bcc.robinfood.com/coupons/remoteEntry.js',
    module: () => import('bcc-coupons/Coupons'),
    route: () => location.pathname.startsWith('/coupons'),
  },
  {
    name: 'integrations_admin',
    alias: 'bcc-integrations-admin',
    path: 'https://modules.bcc.robinfood.com/integrations-admin/remoteEntry.js',
    module: () => import('bcc-integrations-admin/IntegrationsAdmin'),
    route: () => location.pathname.startsWith('/integrations-admin'),
  },
  {
    name: 'spm',
    alias: 'bcc-spm',
    path: 'https://modules.bcc.robinfood.com/spm/remoteEntry.js',
    module: () => import('bcc-spm/Spm'),
    route: () => location.pathname.startsWith('/spm'),
  },
  {
    name: 'ram',
    alias: 'bcc-ram',
    path: 'https://modules.bcc.robinfood.com/ram/remoteEntry.js',
    module: () => import('bcc-ram/Ram'),
    route: () => location.pathname.startsWith('/ram'),
  },
  {
    name: 'sku_admin',
    alias: 'bcc-sku-admin',
    path: 'https://modules.bcc.robinfood.com/sku-admin/remoteEntry.js',
    module: () => import('bcc-sku-admin/SkuAdmin'),
    route: () => location.pathname.startsWith('/sku-admin'),
  },
  {
    name: 'components_stencil',
    alias: 'bcc-components-stencil',
    path: 'https://modules.bcc.robinfood.com/components-stencil/remoteEntry.js',
    module: () => import('bcc-components-stencil/ComponentsStencil'),
    route: () => location.pathname.startsWith('/components-stencil'),
  },
  {
    name: 'menu',
    alias: 'bcc-menu',
    path: 'https://modules.bcc.robinfood.com/menu/remoteEntry.js',
    module: () => import('bcc-menu/Menu'),
    route: () => location.pathname.startsWith('/menu'),
  },
 {
    name: 'foodcoins',
    alias: 'bcc-foodcoins',
    path: 'https://modules.bcc.robinfood.com/foodcoins/remoteEntry.js',
    module: () => import('bcc-foodcoins/Foodcoins'),
    route: () => location.pathname.startsWith('/foodcoins'),
  },
  {
    name: 'nps',
    alias: 'bcc-nps',
    path: 'https://modules.bcc.robinfood.com/nps/remoteEntry.js',
    module: () => import('bcc-nps/Nps'),
    route: () => location.pathname.startsWith('/nps'),
  },
  {
    name: 'store_reception',
    alias: 'bcc-store-reception',
    path: 'https://modules.bcc.robinfood.com/store-reception/remoteEntry.js',
    module: () => import('bcc-store-reception/StoreReception'),
    route: () => location.pathname.startsWith('/reception'),
  },
  {
    name: 'user_assignment',
    alias: 'bcc-user-assignment',
    path: 'https://modules.bcc.robinfood.com/user-assignment/remoteEntry.js',
    module: () => import('bcc-user-assignment/UserAssignment'),
    route: () => location.pathname.startsWith('/logistic-centers'),
  },
 {
    name: 'payment_methods',
    alias: 'bcc-payment-methods',
    path: 'https://modules.bcc.robinfood.com/payment-methods/remoteEntry.js',
    module: () => import('bcc-payment-methods/PaymentMethods'),
    route: () => location.pathname.startsWith('/payment-methods'),
  },
  {
    name: 'rps',
    alias: 'bcc-rps',
    path: 'https://modules.bcc.robinfood.com/rps/remoteEntry.js',
    module: () => import('bcc-rps/Rps'),
    route: () => location.pathname.startsWith('/rps'),
    },
  {
    name: 'services',
    alias: 'bcc-services',
    path: 'https://modules.bcc.robinfood.com/services/remoteEntry.js',
    module: () => import('bcc-services/Services'),
    route: () => location.pathname.startsWith('/services'),
    },
 {
    name: 'dac',
    alias: 'bcc-dac',
    path: 'https://modules.bcc.robinfood.com/dac/remoteEntry.js',
    module: () => import('bcc-dac/Dac'),
    route: () => location.pathname.startsWith('/dac'),
    },
 {
    name: 'scm',
    alias: 'bcc-scm',
    path: 'https://modules.bcc.robinfood.com/scm/remoteEntry.js',
    module: () => import('bcc-scm/Scm'),
    route: () => location.pathname.startsWith('/scm'),
    },
 {
    name: 'dr_validations',
    alias: 'bcc-dr-validations',
    path: 'https://modules.bcc.robinfood.com/dr-validations/remoteEntry.js',
    module: () => import('bcc-dr-validations/DrValidations'),
    route: () => location.pathname.startsWith('/dr-validations'),
    },
 {
    name: 'store_config',
    alias: 'bcc-store-config',
    path: 'https://modules.bcc.robinfood.com/store-config/remoteEntry.js',
    module: () => import('bcc-store-config/StoreConfig'),
    route: () => location.pathname.startsWith('/store-config'),
    },
    { 
        name: 'sales_report', 
        alias: 'bcc-sales-report', 
        path: 'https://modules.bcc.robinfood.com/sales-report/remoteEntry.js', 
        module: () => import('bcc-sales-report/SalesReport'), 
        route: () => location.pathname.startsWith('/sales-report'), 
    },
];

exports.MICROFRONTS = MICROFRONTS;
